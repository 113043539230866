.takes-container {
  text-align: left;
}

.takes-tab {
  background-color: #272B37;
  border: 1px #373E51;
  border-style: solid;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.tab {
  flex-grow: 1;
  list-style: none;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.react-tabs__tab {
  cursor: pointer;
}

.react-tabs__tab--selected {
  background-color: #59627B !important;
  border: none !important;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: none !important;
}