.create-comment-container {
  padding: 0 2%;
}

.create-comment-form {
  display: flex;
  flex-direction: column;
  width: 75%;
  textarea {
    margin-top: 10px;
    margin-bottom: 15px;
    resize: none;
    height: 7vh;
    background-color: #171C28;
    color: white;
    font-family: 'manrope';
    border-radius: 10px;
    padding: 2%;
  }
  button {
    width: 20%;
    padding: 1%;
    font-family: 'manrope';
    background-color: #1AAEFF;
    border-radius: 10px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #1592d5;
  }
}