.leaderboard-container {
  text-align: left;
}

.leaderboard-top-user-container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(180deg, #15264A 0%, #274173 100%);
  height: 300px;
  margin-bottom: 1%;
  border-radius: 20px;
}

.leaderboard-username {
  font-weight: bold;
}
.leaderboard-winrate {
  color: #1AAEFF;
  font-size: small;
}
.leaderboard-total {
  font-size: small;
  font-weight: 500;
}
.leaderboard-ROI {
  color: #6B88BE;
  font-size: small;
  font-weight: 500;
}
.leaderboard-user-roi {
  font-size: small;
  font-weight: 500;
}

.link-to-profile {
  color: white;
  text-decoration: none;
}

.link-to-profile:hover {
  text-decoration: underline;
}

.first-place {
  position: relative;
  width: 40%;
  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
  p {
    margin: 4px;
  }
  .leaderboard-ranking {
    position: absolute;
    display: flex;
    top: 0;
    background-color: #1AAEFF;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
  }
  order: 2;
  align-items: center;
  text-align: center;
}

.second-place {
  position: relative;
  width: 30%;
  height: 80%;
  padding-left: 10px;
  img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
  }
  p {
    margin: 0;
  }
  .leaderboard-ranking {
    position: absolute;
    display: flex;
    top: 0;
    background-color: #1AAEFF;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
  }
  order: 1;
  align-items: center;
  text-align: center;
}

.third-place {
  position: relative;
  width: 30%;
  height: 80%;
  padding-right: 10px;
  img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
  }
  p {
    margin: 0;
  }
  .leaderboard-ranking {
    position: absolute;
    display: flex;
    top: 0;
    background-color: #1AAEFF;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
  }
  order: 3;
  align-items: center;
  text-align: center;
}

.first-place, .second-place, .third-place{
  word-wrap: break-word;
  justify-content: center;
  .leaderboard-ranking{
    right: calc((100% / 3));
  }
}

.leaderboard-user-container {
  background-color: #272B39;
  border-radius: 20px;
  margin-bottom: 1%;
  padding: 2%;
  display: flex;
  img {
    margin-top: 5px;
    border-radius: 50%;
    margin-right: 2%;
  }
  p {
    font-size: 15px;
    padding: 0px;
    margin: 0px;
  }
}

.leaderboard-user-info-container {
  width: 100%;
}

.leaderboard-user-more-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.remaining-ranking {
  margin-left: -22px;
  margin-right: 25px;
  border-color: #555A6D;
  border-style: solid;
  background-color: #30405F;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .first-place, .second-place{
    .leaderboard-ranking{
      right: calc((100% / 12));
    }
  }
  .third-place{
    .leaderboard-ranking{
      right: calc((100% / 4));
    }
  }
  .leaderboard-user-container {
    p {
      font-size: 3vw;
    }
  }
  .first-place {
    img {
      width: 20vw;
      height: 20vw;
    }
  }
  .second-place {
    img {
      width: 10vw;
      height: 10vw;
    }
  }
  .third-place {
    img {
      width: 10vw;
      height: 10vw;
    }
  }
  .leaderboard-username {
    font-weight: bold;
    font-size: 4vw;
  }
  .leaderboard-winrate {
    color: #1AAEFF;
    font-size: 4vw;
  }
  .leaderboard-total {
    font-size: 4vw;
    font-weight: 500;
  }
  .leaderboard-ROI {
    color: #6B88BE;
    font-size: 4vw;
    font-weight: 500;
  }
  .leaderboard-user-roi {
    font-size: 4vw;
    font-weight: 500;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .leaderboard-user-container {
    p {
      font-size: 3vw;
    }
  }
  .first-place, .second-place{
    .leaderboard-ranking{
      right: calc((100% / 7));
    }
  }
  .third-place{
    .leaderboard-ranking{
      right: calc((100% / 4));
    }
  }
}

@media (min-width: 769px) and (max-width: 1020px) {
  .leaderboard-user-container {
    p {
      font-size: 2vw;
    }
  }
  .first-place, .second-place{
    .leaderboard-ranking{
      right: calc((100% / 5));
    }
  }
  .third-place{
    .leaderboard-ranking{
      right: calc((100% / 4));
    }
  }
}

.spinner-container {
  transform: scale(0.5);
  display: inline-block;
  position: relative;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
