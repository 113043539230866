.available-points {
    color: white;
    font-weight: bold;
    padding: 10px;
    padding-left: 25px;
    font-family: 'manrope';
}

.take-container {
    display: flexbox;
    height: auto;
    background-color: #171C28;
    border-radius: 10px;
    padding: 10px;
    margin-left: 25px;
    margin-right: 25px;

    .the-take {
        font-family: 'manrope';
        color: white;
    }

    .take-points {
        padding-top: 20px;
    }

    .buttons {
        background-color: #171C28;
        color: #1AAEFF;
        border: 2px solid;
        border-radius: 15px;
        border-color: #1AAEFF;
        width: 180px;
        height: 50px;
        padding: 5px;
        margin: 5px;
        font-size: medium;
        display: inline-block;
        cursor: pointer;
    }

    .buttons.clicked {
        background-color: #1AAEFF;
        color: white;
    }

    .payout-points {
        display: flex;
    }
    .agree-disagree-payout {
        margin-left: 5px;
        width: 50%;
    }
    .current-payout {
        margin-left: 5px;
        width: 50%;
        font-size: 14px;
        color: #D3D3D3;
    }
}

.promo-points {
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: 'manrope';
    font-weight: bold;
}

.use-promo {
    display: flexbox;
    background-color: #171C28;
    border-radius: 10px;
    padding: 10px;
    margin-left: 25px;
    margin-right: 25px;
    height: 22px;
}

.toggle-container {
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
    left: 300px;
    bottom: 25px;
  }
  
  .toggle-switch {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    transition: all 0.3s;
  }
  
  .toggle-container.on {
    background-color: #1AAEFF;
  }
  
  .toggle-container.on .toggle-switch {
    left: 32.5px;
  }
  
  .toggle-container.off {
    background-color: #272B37;
  }
  
  .toggle-container.off .toggle-switch {
    left: 2.5px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 200px;
    button {
      width: 400px;
      margin-left: 25px;
      padding: 8px 5px;
      margin-top: 14px;
      margin-bottom: 5%;
      background-color: #1AAEFF;
      font-family: 'manrope';
      border-radius: 10px;
      border: #1AAEFF;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }