@import "~react-image-gallery/styles/scss/image-gallery.scss";

.App {
  text-align: center;
}

html, body, #root, .App{
  height: 100%;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.authenticated-layout-container {
  display: flex;
  flex-direction: row;
  background-color: #272B39;
  color: white;
  min-height: 100vh;
  width: 100%;
  overflow: visible;
}

.main-content {
  flex: 5;
  overflow-y: auto;
  background-color: #171C28;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.sidebar-container {
  flex: 1.5;
  margin: 0 3%;
  position: sticky;
  max-height: 100vh;
  top: 0;
  left: 0;
}

.suggested-users-container {
  flex: 2;
  position: sticky;
  max-height: 100vh;
  top: 0;
  right: 0;
}

.main-content-container {
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .authenticated-layout-container {
    overflow-y: visible;
  }
  .sidebar-container {
    width: calc(100% / 7);
    margin: calc(100% / 90);
    flex: 0 0 auto;
    position: sticky;
    max-height: 100vh;
    top: 0;
    left: 0;
  }
  .main-content{
    flex: 1;
    background-color: #171C28;
    border-top-left-radius: 35px;
  }
  .main-content-container {
    width: 85%;
    margin: 0 auto;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .authenticated-layout-container
  {
    overflow-y: visible;
  }
  .sidebar-container{
    flex: 1.2;
    margin: 0;
    max-height: 100vh;
    top: 0;
    left: 0;
    position: sticky;
  }
  .main-content{
    flex: 9;
    background-color: #171C28;
    border-top-left-radius: 35px;
  }
  .main-content-container {
    width: 85%;
    margin: 0 auto;
  }

}

@media (max-width: 1100px) {
  .suggested-users-container {
    display: none;
  }
}