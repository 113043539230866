.indiv-post-container {
  margin-top: 30px;
  text-align: left;

  .post-and-comments-container {
    border-radius: 20px;
    background-color: #272B39;

    .create-comment-container {
      margin-left: 10px;
    }
  }

  .postfeed-container {
    font-size: larger;
    margin-bottom: 0;
  }
  
  h3 {
    margin-left: 2%;
    margin-bottom: 1%;
  }

  hr {
    border: 1px solid #3A4052
  }

  .horizontal-bar-top{
    background-color: #272B39;
    margin-top: -2%;
  }

  margin-bottom: 5%;
}

.back-button {
  margin-bottom: 10px;
  cursor: pointer;
}

.comments-container {
  margin-top: 20px;
  padding: 0 10px;
}

.bottom-space {
  padding: 15px;
}