.home-container {
  text-align: left;
}

.postfeed-link {
  text-decoration: none;
  color: black;
}

h3{
  font-size: 30px;
}

@media (max-width: 480px) {
  h3{
    font-size: 25px;
    margin-bottom: 5px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  h3{
    font-size: 27px;
    margin-bottom: 5px;
  }
}

.spinner-container {
  transform: scale(0.5);
  display: inline-block;
  position: relative;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
