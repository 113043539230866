.pages-container {
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
  margin-top: 0px;
  margin-bottom: 2%;
  align-items: center;
  justify-content: center;
}

.pages-container a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s;
  transition: background-color 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 12px;
}

.pages-container a .svg-icon {
  margin-right: 10px;
  transition: fill 0.3s;
}

.pages-container div:hover .svg-icon,
.pages-container div:hover a{
  fill: #1AAEFF;
  color: #1AAEFF;
  background-color: #26394D;
}

.create-buttons-container {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 10px;

  button {
    margin-bottom: 8%;
    font-family: 'manrope';
    border-radius: 10px;
    padding: 5px;
    background-color: #1AAEFF;
    color: white;
    cursor: pointer;
    border-style: none;
    font-weight: bold;
    font-size: 15px;
  }
  button:hover {
    background-color: #1592d5;
  }
}

.logout-button {
  margin-top: 10px;
}

.create-post-modal {
  position: absolute;
  width: 60%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0, #171C28 97%, #1AAEFF 3%);
  z-index: 2;
  border-radius: 20px;
  color: white;
}

.create-post-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.create-take-modal {
  position: absolute;
  width: 60%;
  height: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0, #171C28 97%, #1AAEFF 3%);
  z-index: 2;
  border-radius: 20px;
  color: white;
}

.create-take-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.react-tooltip {
  .avatar-tooltip-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .user-points {
      font-size: 10px;
    }
    .username-tag {
      font-size: 12px;
    }
    button {
      font-size: 10px;
    }
  }
  .create-new-tooltip-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
    button {
      margin-bottom: 7px;
      font-family: 'manrope';
      border-radius: 10px;
      padding: 7px 11px;
      background-color: #1AAEFF;
      color: white;
      cursor: pointer;
      border-style: none;
      font-weight: bold;
      font-size: 12px;
    }
    button:hover {
      background-color: #1592d5;
    }
  }
}

@media screen and (max-width: 1440px) and (max-height: 750px) {
  .create-buttons-container {
    margin-top: 5px;
    margin-bottom: -10px;
  }
  .pages-container{
    margin-bottom: 15px;
    margin-top: -5px;
    gap: 0;
  }
}

@media (max-width: 768px) {
  .pages-container {
    grid-template-columns: 1fr;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 2%;
  }

  .pages-container div {
    padding: 10px;
  }
  .create-post-modal {
    width: 90%;
    height: 65%;
  }
  .create-take-modal {
    width: 90%;
    height: 65%;
  }
  .create-buttons-container {
    margin: 0 auto;
    margin-top: 40px;
  }
}

.user-profile-container {
  border-radius: 20px;
  opacity: 0.6px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile-inner-container {
  background-color: #3E404D;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;

  .link-to-profile {
    text-decoration: none;
    color: white;
  }
  img {
    border-radius: 50%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 40%;
    height: 40%;
  }
  button {
    margin-bottom: 5%;
    border-radius: 14px;
    padding: 10px;
    font-family: 'manrope';
    width: 50%;
    background-color: #1AAEFF;
    color: white;
    font-weight: 700;
    outline: none;
    border: none;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;
  }
  button:hover {
    background-color: #1592d5;
  }
  .username-tag {
    color: #1AAEFF;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .user-points {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.sidebar-ustadium-logo {
  width: 100%;
}


@media (max-width: 480px) {
  .sidebar-ustadium-logo {
    margin-top: 15%;
    margin-bottom: 15%;
    width: 50px;
  }
  .pages-container {
    margin-bottom: 10px;
    margin-top: -10px;
    gap: 0;
  }
  .pages-container div {
    padding: 0;
  }
  .pages-container a {
    font-size: 0; /* Hide the text by setting font-size to 0 */
    margin: 0;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pages-container a .svg-icon {
    margin-right: 0px;
    height: 28px;
    width: auto;
  }
  .user-profile-container {
    margin-top: 5px;
    border-style: none;
    opacity: 0.6px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .user-profile-inner-container {
    background-color: rgba(0,0,0,0.0);
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      border-radius: 50%;
      margin-top: .4em;
      margin-bottom: 5%;
      width: 44px;
    }
    h3 {
      display: none;
    }
    button {
      min-width: 50px;
      font-size: 8px;
      white-space: nowrap;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .sidebar-ustadium-logo {
    margin-top: 15%;
    margin-bottom: 15%;
    width: 50px;
  }
  .pages-container {
    margin-bottom: 10px;
    margin-top: -10px;
    gap: 0;
  }
  .pages-container div {
    padding: 0;
  }
  .pages-container a {
    font-size: 0; /* Hide the text by setting font-size to 0 */
    margin: 0;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pages-container a .svg-icon {
    margin-right: 0px;
    height: 28px;
    width: auto;
  }
  .user-profile-container {
    margin-top: 5px;
    border-style: none;
    opacity: 0.6px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .user-profile-inner-container {
    background-color: #272B39;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      border-radius: 50%;
      margin-top: .4em;
      margin-bottom: 5%;
      width: 44px;
    }
    h3 {
      display: none;
    }
    button {
      min-width: 50px;
      font-size: 8px;
      white-space: nowrap;
    }
  }
}