.user-profile-page-container {
  text-align: left;
  margin-top: 30px;
}

.takes-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #272B39;
  border-radius: 10px;
  padding: 0 3%;
  width: 40%;
  margin-bottom: 30px;
  h4 {
    margin-bottom: 0;
  }
}

.user-profile-details-container {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2%;
  width: 100%;

  .username-and-follows-you {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-username {
      margin-right: 10px;
    }
    .user-follows-you {
      div {
        background-color: #42454e;
        padding: 0 3px;
        color: #979aa1;
        border-radius: 2px;
      }

      h5 {
        margin-top: 0;
      }
    }
  }

  .user-profile-follow-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  img {
    border-radius: 50%;
    width: 10vw;
    margin-right: 2%;
  }
  .user-displayname {
    margin-bottom: 10px;
  }

  .user-username {
    color: #8E95A4;
    margin-top: 0;
  }
}

.follow-modal {
  position: absolute;
  width: 20%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171C28;
  z-index: 2;
  border-radius: 20px;
  color: white;
  padding: 1%;
}

.follow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.follow-unfollow-button {
  margin-left: 10px;
  background-color: #1AAEFF;
  width: 100px;
  height: 20px;
  border-radius: 15px;
  border-style: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.follow-unfollow-button:hover {
  background-color: #1592d5;;
}

@media (max-width: 480px) {
  .user-profile-details-container {
    img {
      width: 20vw;
    }
  }
  .user-profile-follow-container {
    word-wrap: break-word;
    width: 20%;
    padding-right: 10px;
  }
  .follow-modal {
    width: 60%;
    height: 40%;
  }

  .takes-stats-container {
    width: 70%;
  }
}

@media (min-width: 481px) and (max-width: 1000px) {
  .follow-modal {
    width: 40%;
    height: 40%;
  }

  .takes-stats-container {
    width: 70%;
  }
}