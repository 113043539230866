.create-take-container {
  padding: 0 4%;
  h2{
    margin-top: 30px;
  }
  .close-icon{
    margin-top: 2px;
  }
  .user-avatar-container{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    .user-avatar{
      width: 50px;
    }
    .user-name{
      font-size: 20px;
      position: relative;
      top: -15px;
      word-break: break-all;
    }
  }
  .create-take-input-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .take-input-left{
      display: flex;
      flex-direction: column;
      flex: 1;
      select{
        width: 80%;
        height: 30px;
        padding: 5px;
        border-radius: 10px;
        border-color: #363C4F;
        background-color: #272B39;
        color: white;
        font-family: 'manrope';
        cursor: pointer;
        margin-bottom: 5px;
        box-sizing: border-box;
      }
      .date-picker{
        width: 80%;
        height: 30px;
        cursor: pointer;
        background-color: #272B39;
        font-family: 'manrope';
        color: white;
        padding: 5px;
        border-radius: 10px;
        border-color: #363C4F;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
      }
    }
    .take-input-right{
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.create-take-textarea {
  font-family: 'manrope';
  height: 15vh;
  resize: none;
  color: white;
  background-color: #171C28;
  outline: none;
  border: none;
  padding: 1%;
  font-size: larger;
  margin-bottom: 3%;
}

.create-take-form {
  display: flex;
  flex-direction: column;

  h4 {
    margin-top: 0;
    margin-bottom: 1%;
    font-family: 'manrope';
    font-size: 15px;
  }

  .points-amount-input-container {
    margin-top: 1%;
    width: 30%;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #272B39;
    font-family: 'manrope';
    color: white;
    padding: 5px;
    border-radius: 10px;
    border-color: #363C4F;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
  }

  .submit-take-button {
    margin-top: 3%;
    width: 140px;
    height: 25px;
    background-color: #1AAEFF;
    border-radius: 10px;
    color: white;
    font-size: medium;
    cursor: pointer;
    border-style: none;
  }

  .submit-take-button:hover{
    background-color: #1592d5;
  }

  .promo-point-checkbox{
    font-family: 'manrope';
    font-size: 15px;
  }
}