.comment-container {
  padding: 1% 2%;
  background-color: #272B39;
  border-radius: 20px;
  word-wrap: break-word;
  white-space: pre-wrap;

  a {
    color: white;
  }

  p {
    margin-top: 10px;
  }
  .link-to-profile {
    text-decoration: none;
  }

  .comment-text a {
    text-decoration: underline;
  }

  .comment-text-fix{
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .comment-user-actions-container {
    display: flex;
    flex-direction: row;
    p {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .svg-icon{
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }

  .svg-icon {
    margin-right: 15px;
  }

  .comment-modal {
    position: absolute;
    width: 60%;
    top: 35%;
    left: 22%;
    bottom: 32%;
    background-color: #171C28;
    z-index: 2;
    border-radius: 20px;
    color: white;
  }
  
  .comment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}