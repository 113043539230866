.indiv-suggested-user-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 5%;
  margin-bottom: 10px;

  img {
    margin-right: 10px;
    border-radius: 50%;
  }

  .suggested-user-avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 170px;
  }

  button {
    margin-left: 10px;
    height: 35px;
    min-width: 70px;
    border-radius: 15px;
    outline: none;
    border: none;
    font-family: "manrope";
    background-color: #1aaeff;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }

  button:hover {
    background-color: #1592d5;;
  }
}