.login-page-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #171C28;
  color: white;
}

.login-logo-container {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('../../assets/images/ustadium-login-background.png');
  background-size: cover;
  background-position: center center;
  border-top-right-radius: 2%;
  border-bottom-right-radius: 2%;
  img {
    margin-bottom: 10%;
    width: 95%;
  }
}

.login-form-container {
  flex: 2;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  h1{
    font-size: 2.5vw;
  }
  button{
    font-size: 1.2vw;
    font-weight: bold;
  }
  input{
    font-size: 1.2vw;
  }
}


.loginForm {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 5%;

  input {
    margin-bottom: 5%;
    padding: 10px;
    font-family: 'manrope';
    border-radius: 10px;
  }

  button {
    width: 50%;
    margin: 2% auto;
    padding: 10px;
    font-family: 'manrope';
    background-color: #1AAEFF;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    border-style: none;
  }
  
  button:hover {
    background-color: #1592d5;
  }

}

.logo-circle-container{
  width: 70%;
  aspect-ratio: 1/1;
  top: 279px;
  left: 127px;
  border-radius: 50%;
  opacity: 1;
  background: linear-gradient(180deg, rgba(39, 43, 57, 0) 25%, #214577 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right-svg{
  position: absolute;
  top: 5%;
  right: 5%;
}

.center-svg{
  position: absolute;
  top: 85%;
}

.right-center-svg{
  position: absolute;
  top: 70%;
  right: 10%
}

.bottom-right-svg{
  position: absolute;
  bottom: 5%;
  right: 15%;
}

.bottom-left-svg{
  position: absolute;
  bottom: 5%;
  left: 0;
}

.signup-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #171C28;
  z-index: 2;
  border-radius: 20px;
  color: white;
}

.signup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

@media (max-width: 600px) {
  .login-page-container {
    height: 100vh;
    width: 100%;
    background-color: #171C28;
    color: white;
  }
  .login-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo-circle-container{
      max-width: 300px;
      aspect-ratio: 1/1;
      top: 279px;
      left: 127px;
      border-radius: 50%;
      opacity: 1;
      background: linear-gradient(180deg, rgba(39, 43, 57, 0) 25%, #214577 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-circle-container img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .loginForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      width: 80%;
      height: auto;
      margin: 0 auto;
      margin-bottom: 5%;
      z-index: 1;
      h1 {
        font-size: 5vw;
      }
      input {
        max-width: 100%;
        width: 70vw;
        margin-bottom: 5%;
        padding: 3%;
        font-family: 'manrope';
        font-size: 5vw;
        border-radius: 10px;
      }
      button {
        width: 30vw;
        max-width: 100%;
        margin: 2% auto;
        padding: 2%;
        font-family: 'manrope';
        background: linear-gradient(150.24deg, #1AAEFF 12.02%, #41BCFF 84.41%);
        color: white;
        border-radius: 10px;
        font-size: 3vw;
      }
    }
  }
  .signup-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: #171C28;
    z-index: 2;
    border-radius: 20px;
    color: white;
  }
  
  .signup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}