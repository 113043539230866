.confirm-agree-modal, .confirm-disagree-modal {
  position: absolute;
  width: 450px;
  height: 54%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171C28;
  z-index: 2;
  border-radius: 20px;
  color: white;
  background:#272B37;
}

.confirm-participate-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, 197%);
  z-index: 2;
  border-radius: 20px;
  color: white;

}

.confirm-agree-overlay, .confirm-disagree-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.confirm-participate-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
}

.participate-take-button {
  display: flex;
  justify-content: center;

  button {
    margin-bottom: 5%;
    border: 1px solid #1AAEFF;
    color: #1AAEFF;
    font-family: 'manrope';
    border-radius: 10px;
    padding: 5px;
    background-color: #272B39;
    cursor: pointer;
    width: 80px;
    &:first-child {
      margin-right: 8px;
    }
  }
  button:hover {
    border: 1px solid #1AAEFF;
    color: white;
    font-family: 'manrope';
    border-radius: 10px;
    padding: 5px;
    background-color: #1AAEFF;
    cursor: pointer;
    width: 80px;
    &:first-child {
      margin-right: 8px;
    }
  }
}

@media (max-width: 768px) {
  .confirm-agree-modal, .confirm-disagree-modal { 
    width: 90%;
    height: 45%;
  }
}