.user-following-container {
  height: 100%;
  overflow-y: auto;
  .close-icon{
    margin-right: 2px;
    height: 30px;
  }
  .user-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .link-to-profile {
      color: white;
      text-decoration: none;
    }
  }
}