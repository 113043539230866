.login-header-container {
  background-color: #171C28;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 2%;
  border-radius: 10px;
  button {
    padding: 8px 10%;
    font-family: manrope;
    border-radius: 20px;
    background-color: #1AAEFF;
    color: white;
  }
}