.postfeed-container {
  border-radius: 20px;
  margin-bottom: 3%;
  padding: 2%;
  white-space: pre-wrap;
  background-color: #272B39;

  .post-content-container{
    margin-bottom: 15px;
  }

  .post-text a {
    text-decoration: underline;
    word-wrap: break-word;
  }

  .link-to-profile {
    text-decoration: none;
  }

  .link-to-profile:hover {
    text-decoration: underline;
  }

  .link-to-post {
    text-decoration: none;
  }

  .link-to-post:hover {
    text-decoration: underline;
  }

  .username{
    font-family: Manrope;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
  }
  a {
    color: white;
  }
  p {
    font-size: 20px;
    line-height: 25px;
  }
}

.linkify-p{
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  word-wrap: break-word;
}

.user-avatar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -15px;
  img {
    border-radius: 50%;
    margin-right: 5px;
  }
}

.images-container {
  img {
    margin: 1%;
    border-radius: 10px;
  }
}

.videos-container {
  video {
    margin: 1%;
    border-radius: 10px;
  }
}

.post-details-container {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.post-details-container p {
  display: flex;
  align-items: center;
  color: #8E95A4;
  .svg-icon{
    margin-right: 5px;
    cursor: pointer;
  }
  .comment{
    margin-right: 6px;
  }
  margin-right: 30%;
}

.comment-modal {
  position: absolute;
  width: 60%;
  top: 35%;
  left: 22%;
  bottom: 32%;
  background-color: #171C28;
  z-index: 2;
  border-radius: 20px;
  color: white;
}

.comment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-modal {
  position: absolute;
  width: 88%;
  height: 90%;
  left: 6%;
  top: 5%;
  background-color: black;
  z-index: 2;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-gallery {
  width: 95%;
}

.post-image {
  cursor: pointer;
}

.blog-text-container p {
  font-size: 18px;
}

body.image-modal-open {
  overflow: hidden;
}

.close-icon {
  z-index: 3;
}

@media (max-width: 480px) {
  .postfeed-container{
    p {
      font-size: 4vw;
      line-height: 18px;
    }
  }
  .post-text{
    .text-of-post{
      line-height: 24px;
      letter-spacing: .24px;
    }
  }
  .post-image{
    justify-content: center;
    width: 98%;
    height: auto;
  }
  .videos-container{
    video{
      width: 98%;
      height: auto;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .postfeed-container{
    p {
      font-size: 3vw;
      line-height: 20px;
    }
  }
}

@media (min-width: 769px) and (max-width: 1020px) {
  .postfeed-container{
    p {
      font-size: 2vw;
      line-height: 22px;
    }
  }
}