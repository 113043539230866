.signup-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1AAEFF;
  height: 100%;
  border-radius: 10px;
}

.signup-form-container {
  margin-top: 3%;
  background-color: #171C28;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-form-inner-container{
  display: flex;
  flex-direction: column;
  width: 80%;
  input{
    padding: 2%;
    margin-bottom: 3%;
    font-family: 'manrope';
    border-radius: 6px;
    border-style: none;
  }
  .signup-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
  .signup-button{
    cursor: pointer;
    padding: 2%;
    margin-bottom: 3%;
    font-family: 'manrope';
    width: 50%;
    background-color: #1AAEFF;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border-style: none;
  }
  .signup-button:hover{
    background-color: #1592d5;
  }
}

.icon-header{
  display: flex;
  align-items: center;
  width: 100%;
  .ustadium-icon{
    max-width: 30%;
    max-height: 45px;
    margin-right: 2%;
  }
  h1{
    flex: 1;
    font-size: 30px;
    font-family: 'manrope';
  }
}

.close-icon{
  position: absolute;
  width: auto;
  height: 6%;
  right: 1%;
  top: 3%;
  fill: white;
  cursor: pointer;
}

.input-icon{
  margin-bottom: 2%;
  font-family: 'manrope';
  .input-icon-svg{
  position: relative;
  top: 5px;
  margin-right: 1%;
  }
}

@media (max-width: 300px) {
  .icon-header{
    .ustadium-icon{
      max-width: 10vw;
    }
    h1{
      flex: 1;
      font-size: 5vw;
    }
  }
  .input-icon{
    font-size: 3vw;
  }
}