.takes-filters-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  border-bottom: 1px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #363C4F;
  background-color: #272B39;
  color: white;
  font-family: 'manrope';
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100px;
  height: 100px;

  svg-icon {
    width: 22px;
    height: 22px;
  }
  span {
    padding: 3px;
    font-size: 15px;
  }

  &:hover, &.selected {
    background-color: #363C4F;
  }

  &:focus {
    outline: none;
  }
}

.spinner-container {
    transform: scale(0.5);
    display: inline-block;
    position: relative;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  