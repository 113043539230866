.buy-points-form-container {
  .buy-points-options-container {
    margin-bottom: 20px;
    button {
      background-color: #3E404D;
      color: white;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      margin-right: 15px;
      padding: 5px 10px;
      font-family: 'manrope';
      cursor: pointer;
    }
    .selected {
      background-color: #1AAEFF;
    }
  }

  label {
    margin-right: 10px;
    font-weight: bold;
  }
  .custom-value-input-container {
    margin-bottom: 20px;
    background-color: #3E404D;
    border: none;
    padding: 7px;
    color: white;
    font-family: 'manrope';
    border-radius: 10px;
  }
  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .no-spinner {
    -moz-appearance: textfield;
  }

  .name-input-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    input {
      margin-right: 10px;
      width: 175px;
    }
  }

  .card-element-container {
    margin-bottom: 10px;
    width: 75%;
    background-color: #3E404D;
    padding: 15px;
    border-radius: 10px;
  }
  .submit-pay-button {
    color: white;
    background-color: #1AAEFF;
    font-family: 'manrope';
    border: none;
    border-radius: 10px;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .submit-pay-button:hover {
    background-color: #1692D4;
  }

  .spinner-container {
    transform: scale(0.5);
    display: inline-block;
    position: relative;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

[role='dialog'].confirm-payment-popup-content {
  background: #171B28;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  width: 25%;
  min-width: 350px;
  height: 150px;
  padding: 20px;

  .confirm-payment-popup-content-inner {
    text-align: center;
  }
  .confirm-popup-buttons-container {
    button {
      color: white;
      font-family: 'manrope';
      border: none;
      border-radius: 10px;
      padding: 8px 12px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      margin-right: 20px;
    }
    .confirm-payment-button {
      background-color: #1AAEFF;
    }
    .confirm-payment-button:hover {
      background-color: #1692D4;
    }
    .cancel-button {
      background-color: rgb(177, 2, 2);
    }
    .cancel-button:hover {
      background-color: rgb(131, 2, 2);
    }
  }
}
