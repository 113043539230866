.confirm-agree-container {
  display: flex;
  justify-content: center;
  height: 70%;
  width: 100%;

  .take-points{
    margin-bottom: 0px;
  }
  .take-svg-icon{
    flex-basis: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 20px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 200px;
    button {
      width: 400px;
      padding: 8px 5px;
      margin-top: 14px;
      margin-bottom: 5%;
      background-color: #1AAEFF;
      font-family: 'manrope';
      border-radius: 10px;
      border: #1AAEFF;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.updated-points{
  color: #1AAEFF;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 265px;
  margin-top: 10px;
  height: 0;
}
.updated-points p {
  margin: 8px 0;
  padding: 0;
}
.after-joining-label{
  color: #1AAEFF;
  justify-content: left;
  margin-left: 25px;
  margin-top: 0;
}