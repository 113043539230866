.takefeed-container {
  border-radius: 20px;
  margin-bottom: 3%;
  padding-left: 2%;
  background: #272B37;
  font-size: 20px;
  word-wrap: break-word;
  .username-and-take{
    font-family: Manrope;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    word-break: keep-all;
  }
  a {
    color: white;
  }

  .take-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .share-icon {
    filter: brightness(3);
    cursor: pointer;
    position: relative;
    min-height: 40px;
    min-width: 40px;
    padding: 2%;
  }

  .link-to-profile {
    text-decoration: none;
  }

  .link-to-profile:hover {
    text-decoration: underline;
  }

  .link-to-take {
    text-decoration: none;
  }

  .link-to-take:hover {
    text-decoration: underline;
  }

  .agree-disagree-outer-container{
    border-radius: 20px;
    cursor: pointer;
  }

  .agree-disagree-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .progress-bar-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 400px;
    padding-top: 10px;
  }

  .progress-text-left {
    position: absolute;
    font-size: 15px;
    top: 50%;
    right: 50%;
    left: 23%;
    bottom: 15%;
    transform: translate(-50%, -50%);
    color: white;
    white-space: nowrap;
  }
  .progress-text-right {
    position: absolute;
    font-size: 15px;
    top: 50%;
    right: 50%;
    left: 83%;
    bottom: 15%;
    transform: translate(-50%, -50%);
    color: white;
    white-space: nowrap;
  }
  
  #progress-bar {
    width: 85%;
    height: 20px;
    appearance: none;
    border-radius: 10px;
    overflow: hidden;

    &::-webkit-progress-bar {
      background-color: #15264A ;
      border-radius: 10px;
    }

    &::-webkit-progress-value {
      background-color: #1AAEFF;
      border-radius: 10px;
    }

    &::-moz-progress-bar {
      background-color: #1AAEFF;
      border-radius: 10px;
    }
  }

  .take-line {
    width: 90%;
    border-color: #1aafff77;
  }

  .take-info-one, .take-info-two, .take-info-three {
    text-align: center;
    flex: 1;
    line-height: .8;
    margin-top: 10px;
  }

  .take-info-final-pot {
    font-size: medium;
    margin-top: -10px;
  }

  .take-info-text {
    font-weight: bold;
  }

  .take-agree-disagree-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 15px 0;
  }

  .take-agree-disagree-button button {
    flex: 0 0 40%;
    margin: 0 3%;
    margin-bottom: 1%;
    border-radius: 14px;
    padding: 10px;
    font-family: 'manrope';
    background-color: #1AAEFF;
    color: white;
    font-weight: 700;
    outline: none;
    border:#1AAEFF;
    //border: none;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;
  }

  .participated-button {
    flex: 0 0 40%;
    margin: 0 3%;
    margin-bottom: 1%;
    margin-top: 1%;
    border-radius: 14px;
    padding: 10px;
    font-family: 'manrope';
    color: #a0c6dd;
    font-weight: 700;
    text-align: center;
    font-size: medium;
    outline: none;
    border:#1f79b0;
    background-color: #1f79b0;
    transition: box-shadow .3s ease-in-out
  }

  .countdown-icon {
    transform: translate(-25%, 10%);
  }

  .countdown {
    flex: 0 0 28%;
    margin: 0 3%;
    margin-bottom: 1%;
    border-radius: 20px;
    padding: 10px;
    font-family: 'manrope';
    background-color: #363C4F;
    color: white;
    font-weight: 700;
    outline: none;
    font-size: 16px;
    text-align: center;
  }

  .countdown-container {
    position: relative;
    top: -11%;
  }

  .take-agree-disagree-button button:hover{
    background-color: #1AAEFF;
    color: white;
    border: none;
  }

  .joined-take-details {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    p {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .take-info-container {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
  }

  .agree-container, .disagree-container {
    margin-top: 3%;
    width: 40%;
    .take-svg-icon {
      width: 90%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.confirm-agree-modal, .confirm-disagree-modal {
  position: absolute;
  width: 450px;
  height: 45%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #171C28;
  z-index: 2;
  border-radius: 20px;
  color: white;
  background: linear-gradient(0, #171C28 97%, #1AAEFF 3%);
}

.confirm-agree-overlay, .confirm-disagree-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

@media (max-width: 768px) {
  .confirm-agree-modal, .confirm-disagree-modal { 
    width: 90%;
    height: 45%;
  }
}

@media (max-width: 480px) {
  .agree-container, .disagree-container {
    min-width: 20%;
    .take-svg-icon {
      max-width: 50%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: large;
    }
  }
  .progress-bar-container{
    max-width: 20%;
  }

  .takefeed-container {
    font-size: 4vw;
    .take-top-text{
      line-height: 24px;
      letter-spacing: .24px;
    }
    .take-info-text{
      font-size: .8em;
    }
    .take-label{
      margin-top: 0;
      line-height: 24px;
      letter-spacing: .24px;
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .agree-container, .disagree-container {
    h2 {
      font-size: large;
    }
  }
  .progress-bar-container{
    max-width: 20%;
  }

  .takefeed-container {
    font-size: 3vw;
  }
}