.create-post-container {
  padding: 0 4%;
  h2{
    margin-top: 30px;
  }
  .user-avatar-container{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    .user-avatar{
      width: 50px;
    }
    .user-name{
      font-size: 20px;
      position: relative;
      top: -15px;
      word-break: break-all;
    }
  }
}

.create-post-textarea {
  font-family: 'manrope';
  height: 20vh;
  resize: none;
  color: white;
  background-color: #171C28;
  border: none;
  outline: none;
  padding: 1%;
  font-size: larger;
  margin-bottom: 3%;
}

.create-post-form {
  display: flex;
  flex-direction: column;
  .upload-file-container{
    display: flex;
    .upload-file-name{
      margin-left: 5px;
    }
  }
  .upload-file-button{
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
  }
  .upload-file-icon {
    cursor: pointer;
    display: block;
  }
  .upload-file-button input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .upload-file-button input::file-selector-button {
    cursor: pointer;
  }
  .create-post-button {
    width: 90px;
    height: 25px;
    background-color: #1AAEFF;
    border-radius: 10px;
    color: white;
    font-size: medium;
    border-style: none;
  }
  .create-post-button:hover{
    background-color: #1592d5;
    cursor: pointer;
  }
}